

































































































import CustomFieldListForm from "@/components/report/CustomFieldListForm.vue";
import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";
import Debug from "@/components/utility/Debug.vue";
import MActionList from "@/components/utility/mmmint/MActionList.vue";
import MDetailViewGrid from "@/components/utility/mmmint/MDetailViewGrid.vue";
import MHeader, { IAction, IBreadcrumb } from "@/components/utility/mmmint/MHeader.vue";
import MoneyInput from "@/components/utility/MoneyInput.vue";
import RefsSelect from "@/components/utility/RefsSelect.vue";
import RefsSelected from "@/components/utility/RefsSelected.vue";
import { handleError } from "@/lib/utility/handleError";
import { $t } from "@/lib/utility/t";
import { ThirdPartySystemGoToHelper } from "@/lib/utility/third-party-system.go-to-helper";
import { PageFilterElement } from "@/models/page-filter-element.entity";
import { IReference, Reference } from "@/models/reference.entity";
import { IThirdPartySystem } from "@/models/third-party-system.entity";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { OperationModule } from "@/store/modules/operation.store";
import { PartnerModule } from "@/store/modules/partner";
import { mixins } from "vue-class-component";
import { Component, Prop, Vue } from "vue-property-decorator";
import OperationTable from "../operations/OperationTable.vue";
import ActivityCard from "../thg/ActivityCard.vue";
import PartnerReportDetailSendToKsrDialog from "../partner/PartnerReportDetailSendToKsrDialog.vue";
import PartnerReportDetailSendToDaDialog from "../partner/PartnerReportDetailSendToDaDialog.vue";
import { TpsEnum } from "@/lib/enum/third-party-system-type.enum";
import { Operation } from "@/models/operation.entity";

@Component({
  components: {
    MHeader,
    MDetailViewGrid,
    ActivityCard,
    RefsSelect,
    CustomFieldListForm,
    MActionList,
    ConfirmActionDialog,
    MoneyInput,
    OperationTable,
    PartnerReportDetailSendToKsrDialog,
    PartnerReportDetailSendToDaDialog,
    RefsSelected,
    Debug
  }
})
export default class ThirdPartySystemCard extends mixins(Vue) {
  readonly BackendResourceEnum = BackendResourceEnum;
  selected: string[] = [];
  @Prop()
  value!: IThirdPartySystem;

  @Prop()
  hideBreadCrumbs!: boolean;

  @Prop()
  showDetailButton!: boolean;

  isDeleteDialogActive = false;

  isLoadingDelete = false;

  isLoadingRefsUpdate = false;

  isExportSignDialogActive = false;

  isExportSignLoading = false;

  get signIds() {
    const uniqueSignIds: IReference[] = [];

    for (const ref of this.value.refs) {
      if (ref.refType === BackendResourceEnum.SIGN && !uniqueSignIds.find(r => r.refId === ref.refId)) {
        uniqueSignIds.push(ref);
      }
    }

    return uniqueSignIds;
  }

  get isDa() {
    return this.value.system === TpsEnum.DA;
  }

  get isKsr() {
    return this.value.system === TpsEnum.KSR;
  }

  get title() {
    return this.value.system + " - " + this.value.externalId;
  }

  get partner() {
    return PartnerModule.partner;
  }

  get partnerId(): string {
    return this.$route.params.partnerId;
  }

  get breadCrumbs(): IBreadcrumb[] | undefined {
    if (this.hideBreadCrumbs) {
      return undefined;
    }

    const crumbs: IBreadcrumb[] = [];

    crumbs.push({
      exact: true,
      text: $t("thirdPartySystem.thirdPartySystems"),
      to: ThirdPartySystemGoToHelper.locations.thirdPartySystemTable({ partnerId: this.partnerId })
    });

    crumbs.push({
      exact: true,
      text: this.title,
      to: ThirdPartySystemGoToHelper.locations.thirdPartySystemDetail({
        partnerId: this.partnerId,
        thirdPartySystemId: this.value.id
      })
    });

    return crumbs;
  }

  get actions(): IAction[] | undefined {
    const actions: IAction[] = [];

    if (this.showDetailButton) {
      actions.push({
        key: "detail",
        text: $t("common.nouns.detail"),
        exec: () => this.value.goToDetail(this.$router, false)
      });
    }

    if (this.isKsr) {
      if (this.value.refs.find(r => r.refType === BackendResourceEnum.REPORT)) {
        actions.push({
          key: "SendToKsrDialog",
          text: $t("thirdPartySystem.exportReport"),
          exec: () => (this.$refs.partnerReportDetailSendToKsrDialog as PartnerReportDetailSendToKsrDialog).open()
        });
      }
      if (this.value.refs.find(r => r.refType === BackendResourceEnum.SIGN)) {
        actions.push({
          key: "ExportSign",
          text: $t("thirdPartySystem.exportSign"),
          exec: () => (this.isExportSignDialogActive = true)
        });
      }
    }
    if (this.isDa) {
      if (this.value.refs.find(r => r.refType === BackendResourceEnum.REPORT)) {
        actions.push({
          key: "ExportSign",
          text: $t("thirdPartySystem.exportReport"),
          exec: () => (this.$refs.partnerReportDetailSendToDaDialog as PartnerReportDetailSendToDaDialog).open()
        });
      }
      if (this.value.refs.find(r => r.refType === BackendResourceEnum.SIGN)) {
        actions.push({
          key: "ExportSign",
          text: $t("thirdPartySystem.exportSign"),
          exec: () => (this.isExportSignDialogActive = true)
        });
      }
    }

    return actions;
  }

  get source(): IReference {
    return new Reference({
      refId: this.value.id,
      refType: BackendResourceEnum.TPS
    });
  }

  beforeMount() {
    OperationModule.setHiddenFilters([
      new PageFilterElement({
        value: this.value.id,
        operation: "$eq",
        key: "refs.refId"
      })
    ]);
  }

  beforeDestroy() {
    OperationModule.setHiddenFilters([]);
  }

  onDelete() {
    this.isLoadingDelete = true;
    this.value.delete().catch(handleError);
    this.isDeleteDialogActive = false;
    this.isLoadingDelete = false;
  }

  async updateRefs() {
    this.isLoadingRefsUpdate = true;
    await this.value.update().catch(handleError);
    this.isLoadingRefsUpdate = false;
  }

  async exportSigns() {
    this.isExportSignLoading = true;
    await Promise.all(
      this.selected.map(async signRequestId => {
        await this.exportSignRequest(signRequestId);
      })
    );
    this.isExportSignLoading = false;
  }

  async exportSignRequest(signRequestId: string) {
    try {
      const operation = await this.value.exportSignRequest(signRequestId);
      if (operation) {
        new Operation({ partnerId: this.partnerId, id: operation.operationId }).fetch();
        OperationModule.dispatchToast({ operationId: operation.operationId, partnerId: this.partnerId });
      }
      this.isExportSignDialogActive = false;
    } catch (e) {
      handleError(e);
    }
  }
}
