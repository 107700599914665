var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "600px"
    },
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('MachineCard', {
    attrs: {
      "machine": _vm.selectedMachine
    }
  }), _c('MachineQrCodeCard', {
    attrs: {
      "manufacturer": _vm.manufacturer,
      "machine": _vm.selectedMachine
    }
  })], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.closeDialog
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("close")) + " ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }