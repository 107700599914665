import { render, staticRenderFns } from "./DocumentTemplateDetailLink.vue?vue&type=template&id=153e1c7b&scoped=true&"
import script from "./DocumentTemplateDetailLink.vue?vue&type=script&lang=ts&"
export * from "./DocumentTemplateDetailLink.vue?vue&type=script&lang=ts&"
import style0 from "./DocumentTemplateDetailLink.vue?vue&type=style&index=0&id=153e1c7b&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "153e1c7b",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VWindow } from 'vuetify/lib/components/VWindow';
import { VWindowItem } from 'vuetify/lib/components/VWindow';
installComponents(component, {VBtn,VCard,VListItem,VListItemContent,VListItemTitle,VProgressLinear,VSpacer,VSubheader,VWindow,VWindowItem})
