
















































































































































































































































import { ITemplateContext } from "@/lib/interfaces/template/templateContext.interface";
import { detailedDate } from "@/lib/utility/date-helper";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { TimeStampEntity } from "@/models/timestampEntity";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Prop, Watch } from "vue-property-decorator";
import TemplateCard from "@/components/template/TemplateCard.vue";
import TemplateDialog from "@/components/template/TemplateDialog.vue";
import TemplateEditor from "@/components/template/TemplateEditor.vue";
import TimelineCard from "@/components/utility/TimelineItem.vue";
import { PartnerUserModule } from "@/store/modules/partner-user.store";
import { IPartnerMessage, messageFolderIconMap } from "@/models/partner-message.entity";
import MHeader, { IAction, IBreadcrumb } from "@/components/utility/mmmint/MHeader.vue";
import { handleError } from "@/lib/utility/handleError";
import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";
import { $t } from "@/lib/utility/t";
import { avatar } from "@/lib/utility/mail-helper";
import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";
import { MessageFolderEnum } from "@/lib/enum/message-folder.enum";
import { ActivityTypeEnum } from "@/store/modules/activity-log.store";
import SelectAssignees from "@/components/utility/SelectAssignees.vue";
import MDetailViewGrid from "@/components/utility/mmmint/MDetailViewGrid.vue";
import ViewedPreview from "@/components/utility/ViewedPreview.vue";
import RefsSelect from "@/components/utility/RefsSelect.vue";
import PdfViewer from "@/components/files/v2/PdvViewer.vue";
import { downloadFileViaAnchor } from "@/lib/utility/downloadFileFunc";

@Component({
  components: {
    MHeader,
    TemplateEditor,
    TimelineCard,
    TemplateCard,
    TemplateDialog,
    LatestEntriesCardEmpty,
    ConfirmActionDialog,
    MDetailViewGrid,
    SelectAssignees,
    ViewedPreview,
    RefsSelect,
    PdfViewer
  }
})
export default class PartnerMessageDetail extends PermissionMixin {
  @Prop({ default: false })
  loading!: boolean;

  @Prop()
  value?: IPartnerMessage;

  isMounting = false;

  isArchiveDialogOpen = false;

  isArchiveLoading = false;

  isMarkUnreadLoading = false;

  unMarkMenu = false;

  isLoadingRefs = false;

  isMoveLoading = false;

  isMoveDialogOpen = false;

  isAttachmentsLoaded = false;

  isAttachmentsLoading = false;

  isOverflowing = false;

  isExpanded = false;

  selectedAttachment: File | null = null;

  isAttachmentDetailOpen = false;

  get folderOptions() {
    return Object.values(MessageFolderEnum);
  }

  get messageFolderIconMap() {
    return messageFolderIconMap;
  }

  get isMobile() {
    return this.$vuetify.breakpoint.smAndDown;
  }

  get actions(): IAction[] {
    const actions: IAction[] = [];

    if (!this.$route.params.messageId) {
      actions.push({
        text: $t("common.nouns.detail"),
        key: "detail",
        icon: "mdi-open-in-new",
        exec: () => this.value?.goToDetail(this.$router)
      });
    }

    actions.push({
      text: $t("common.verbs.reply"),
      key: "Reply",
      icon: "mdi-reply-outline",
      exec: () => (this.$refs.templateDialog as TemplateDialog)?.show()
    });

    if (this.value?.url) {
      actions.push({
        text: $t("common.verbs.download"),
        key: "Download",
        icon: "mdi-download",
        exec: () => this.value?.download()
      });
    }

    if (this.value?.folder !== MessageFolderEnum.ARCHIVE) {
      actions.push({
        text: $t("common.verbs.archive"),
        key: "Archive",
        icon: "mdi-inbox",
        exec: () => (this.isArchiveDialogOpen = true)
      });
    }

    actions.push({
      text: $t("common.verbs.move"),
      key: "move",
      icon: "mdi-inbox-multiple",
      exec: () => (this.isMoveDialogOpen = true)
    });

    return actions;
  }

  get breadCrumbs(): IBreadcrumb[] {
    const breadCrumbs: IBreadcrumb[] = [];
    const isDetailView = this.$route.params.messageId;

    breadCrumbs.push({
      text: $t("common.nouns.inboxes"),
      to: { name: "PartnerMessageInboxList" },
      exact: true
    });

    const inbox = PartnerModule.partner.settings?.inboxes?.find(inbox => inbox.identifier === this.value?.to);
    if (inbox && isDetailView) {
      breadCrumbs.push({
        text: inbox.name,
        to: {
          name: "PartnerMessageInbox",
          params: { partnerId: this.value?.partnerId ?? "", inboxId: this.value?.to ?? "" },
          query: { folder: this.value?.folder.toString() ?? "" }
        },
        exact: true
      });
    }

    if (this.value?.folder) {
      breadCrumbs.push({
        text: $t("MessageFolderEnum." + this.value?.folder),
        to: { name: "PartnerMessageDetail" },
        exact: true,
        disabled: true
      });
    } else {
      breadCrumbs.push({
        text: $t("common.nouns.detail"),
        to: { name: "PartnerMessageDetail" },
        exact: true,
        disabled: true
      });
    }
    return breadCrumbs;
  }

  get chips(): IAction[] | undefined {
    return undefined;
  }

  /**
   * E.g. "user xy from mrfiktiv"
   */
  get fromMail() {
    let fromMail = PartnerModule.partner.companyName;
    let fromUser = "";

    if (this.value?.folder === MessageFolderEnum.OUTBOX) {
      return this.value?.to;
    }

    if (this.value?.from) {
      fromMail = this.value.from;
    }

    if (this.userNameForId) {
      fromUser = this.$t("components.PartnerMessageDetail.toAlt", { mail: this.userNameForId }).toString();
    }

    return fromUser + fromMail;
  }

  get date() {
    if ((this.value?.timestamp as TimeStampEntity).created) {
      const locale = this.$t("utility.toLocalDateString").toString() || "de-de";

      return detailedDate((this.value?.timestamp as TimeStampEntity).created, locale);
    }
    return "";
  }

  get partner() {
    return PartnerModule.partner;
  }

  get from() {
    return this.partner;
  }

  get newMessageTo() {
    if (this.value?.from) {
      return [this.value.from];
    }

    return this.to;
  }

  get to(): string[] {
    if (!this.value?.to) {
      return [];
    }
    return [this.value?.to];
  }

  get context(): ITemplateContext {
    return { partner: this.partner };
  }

  get userNameForId() {
    if (this.value?.userId === undefined) {
      return "";
    }

    const user = PartnerUserModule.maps.id.get(this.value.userId)[0];
    if (!user) {
      return "";
    }

    return user.firstName + " " + user.lastName;
  }

  mounted() {
    this.getAttachments();
  }

  getObjectUrl(file: File) {
    return URL.createObjectURL(file);
  }

  selectAttachment(attachment: File) {
    this.closeSelectAttachment();

    this.$nextTick(() => {
      this.selectedAttachment = attachment;
      this.isAttachmentDetailOpen = true;
    });
  }

  closeSelectAttachment() {
    this.selectedAttachment = null;
    this.isAttachmentDetailOpen = false;
  }

  getAvatar(mail: string) {
    return avatar(mail);
  }

  @Watch("value.attachments.length")
  checkOverflow() {
    const container = this.$refs.container as HTMLElement;
    this.isOverflowing = container.scrollHeight > container.clientHeight;
  }

  toggleExpand() {
    this.isExpanded = !this.isExpanded;
  }

  getIconForImageType(file: File) {
    if (this.isImage(file)) {
      return "mdi-image";
    } else if (this.isPdf(file)) {
      return "mdi-file";
    }
  }

  isImage(file: File) {
    return [".png", ".jpg", ".jpeg"].find(suff => file.name.endsWith(suff));
  }

  isPdf(file: File) {
    return [".pdf"].find(suff => file.name.endsWith(suff));
  }

  abortArchive() {
    this.isArchiveDialogOpen = false;
  }

  fileSizeString(fileSize: number) {
    return `${Math.round((fileSize / (1024 * 1024)) * 100) / 100}MB`;
  }

  async archive() {
    try {
      if (!this.value) {
        return;
      }
      this.isArchiveLoading = true;
      await this.value.archive();
    } catch (e) {
      handleError(e);
    } finally {
      this.isArchiveDialogOpen = false;
      this.isArchiveLoading = false;
    }
  }

  async markUnread() {
    if (!this.value || this.isMarkUnreadLoading) {
      return;
    }

    this.isMarkUnreadLoading = true;
    await this.value.markUnread().catch(handleError);
    this.isMarkUnreadLoading = false;
    this.unMarkMenu = false;
  }

  async onAssigneesUpdate(assignees: []) {
    if (!this.value) return;

    await this.value.updatePartial({ assignees });
  }

  async onAssigneesAdded(assignees: string[]) {
    if (!this.value) return;

    await this.value.createAssigneeActivity(ActivityTypeEnum.CREATE_ASSIGNEE, assignees);
  }

  async onAssigneesRemoved(assignees: string[]) {
    if (!this.value) return;

    await this.value.createAssigneeActivity(ActivityTypeEnum.DELETE_ASSIGNEE, assignees);
  }

  async saveRefs() {
    if (!this.value) return;

    this.isLoadingRefs = true;
    await this.value
      .updatePartial({
        refs: this.value?.refs
      })
      .catch(handleError);
    this.isLoadingRefs = false;
  }

  async setNewFolder(folder: MessageFolderEnum) {
    if (!this.value) return;

    this.isMoveLoading = true;
    await this.value
      .updatePartial({
        folder: folder
      })
      .catch(handleError);
    this.isMoveLoading = false;
  }

  async getAttachments() {
    this.isAttachmentsLoaded = false;
    this.isAttachmentsLoading = true;
    await this.value?.getAttachments().catch(e => this.$log.error(e));
    this.isAttachmentsLoading = false;
    this.isAttachmentsLoaded = true;
  }

  downloadFile(file: File) {
    downloadFileViaAnchor(file, file.name);
  }
}
