

































































































































































import CustomerContactCard from "@/components/partner/CustomerContactCard.vue";
import PartnerDetailAttributeWithToolTip from "@/components/partner/PartnerDetailAttributeWithToolTip.vue";
import PartnerRegistrationCard from "@/components/partner/PartnerRegistrationCard.vue";
import PartnerReportDetailImageCard from "@/components/partner/PartnerReportDetailImageCard.vue";
import PartnerReportDetailImageTimeLineElement from "@/components/partner/PartnerReportDetailImageTimeLineElement.vue";
import PartnerReportDetailInfoDateCard from "@/components/partner/PartnerReportDetailInfoDateCard.vue";
import PartnerReportDetailInfoMessageCard from "@/components/partner/PartnerReportDetailInfoMessageCard.vue";
import PartnerReportDetailMetaCard from "@/components/partner/PartnerReportDetailMetaCard.vue";
import { IExportReportMeta } from "@/lib/interfaces/Report/export-report-meta.interface";
import { detailedDate, simpleDate } from "@/lib/utility/date-helper";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import FeatureMixin from "@/mixins/FeatureMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { RegistrationResultsEntity } from "@/models/registrationResultsEntity";
import { IUser } from "@/models/user.entity";
import { ExportModule } from "@/store/modules/export.store";
import { FeatureModule } from "@/store/modules/feature.store";
import { PartnerModule } from "@/store/modules/partner";
import { UserModule } from "@/store/modules/me-user.store";
import ReportDocumentSignRequestForm from "@/views/sign/ReportDocumentSignRequestForm.vue";
import { mixins } from "vue-class-component";
import { Component, Watch } from "vue-property-decorator";
import TemplateEditor from "../template/TemplateEditor.vue";
import TimelineCard from "../utility/TimelineItem.vue";
import ActivityTimeLine from "./ActivityTimeLine.vue";
import CustomerContactTimeLineElement from "./CustomerContactTimeLineElement.vue";
import PartnerRegistrationTimeLineElement from "./PartnerRegistrationTimeLineElement.vue";
import PartnerReportDetailInfoBodyTimeLineElement from "./PartnerReportDetailInfoBodyTimeLineElement.vue";
import PartnerReportDetailInfoWitnessCard from "./PartnerReportDetailInfoWitnessCard.vue";
import PartnerReportDetailInfoWitnessTimeLineElement from "./PartnerReportDetailInfoWitnessTimeLineElement.vue";
import PartnerReportDetailMetaTimeLineElement from "./PartnerReportDetailMetaTimeLineElement.vue";
import SendToDaDialog from "./PartnerReportDetailSendToDaDialog.vue";
import SendToExternOption from "./PartnerReportDetailSendToExternOption.vue";
import SendToKsrDialog from "./PartnerReportDetailSendToKsrDialog.vue";
import SendToPdrDialog from "./PartnerReportDetailSendToPdrDialog.vue";
import ReportActivityBoxCommentComponent from "./ReportActivityBoxCommentComponent.vue";
import ReportActivityBoxDocumentComponent from "./ReportActivityBoxDocumentComponent.vue";
import ReportActivityBoxMailComponent from "./ReportActivityBoxMailComponent.vue";
import ReportActivityBoxShareComponent from "./ReportActivityBoxShareComponent.vue";
import ReportBaseTimeLine from "./ReportBaseTimeLine.vue";
import { MrfiktivReferenceGen } from "@/services/mrfiktiv/v1/data-contracts";
import ReportActivityBoxImageComponent from "./ReportActivityBoxImageComponent.vue";
import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";
import PartnerReportTimeLineCardScrollMixin from "./PartnerReportTimeLineCardScrollMixin.vue";
import Debug from "../utility/Debug.vue";
import { ThirdPartySystemModule } from "@/store/modules/third-party-system.store";
import { TpsEnum } from "@/lib/enum/third-party-system-type.enum";

@Component({
  components: {
    PartnerDetailAttributeWithToolTip,
    CustomerContactCard,
    PartnerReportDetailInfoMessageCard,
    PartnerReportDetailInfoDateCard,
    PartnerRegistrationCard,
    PartnerReportDetailImageCard,
    ReportActivityBoxShareComponent,
    PartnerReportDetailMetaCard,
    PartnerReportDetailInfoWitnessCard,
    PartnerReportDetailInfoBodyTimeLineElement,
    PartnerReportDetailImageTimeLineElement,
    CustomerContactTimeLineElement,
    PartnerReportDetailInfoWitnessTimeLineElement,
    PartnerReportDetailMetaTimeLineElement,
    PartnerRegistrationTimeLineElement,
    SendToExternOption,
    SendToKsrDialog,
    SendToDaDialog,
    SendToPdrDialog,
    ReportBaseTimeLine,
    ReportDocumentSignRequestForm,
    ActivityTimeLine,
    ReportActivityBoxCommentComponent,
    ReportActivityBoxMailComponent,
    ReportActivityBoxImageComponent,
    TimelineCard,
    TemplateEditor,
    ReportActivityBoxDocumentComponent,
    ConfirmActionDialog,
    Debug
  }
})
export default class PartnerReportDetailInfoTimeLineCard extends mixins(
  PartnerReportTimeLineCardScrollMixin,
  DarkModeHighlightMixin,
  PermissionMixin,
  FeatureMixin
) {
  registrationUpdateLoading = false;
  tab = 0;
  digitalSignatureRequest = false;
  selected = "";
  isDialogActive = false;
  isImageDialogActive = false;

  sheet = false;

  get mobileMenu() {
    const menu = [
      {
        title: this.$t("timeLine.PartnerReportDetailInfoTimeLineCard.actionCard.comment"),
        action: this.showCommentDialog,
        icon: "mdi-message",
        color: "primary",
        isEnabled: true
      }
    ];
    if (this.canCreateImage) {
      menu.push({
        title: this.$t("timeLine.PartnerReportDetailInfoTimeLineCard.actionCard.image"),
        action: () => (this.isImageDialogActive = true),
        icon: "mdi-camera",
        color: "primary",
        isEnabled: true
      });
    }
    if (this.canCreateMessage) {
      menu.push({
        title: this.$t("timeLine.PartnerReportDetailInfoTimeLineCard.actionCard.email"),
        action: this.showEmailDialog,
        icon: "mdi-email",
        color: "primary",
        isEnabled: this.isTemplateEnabled
      });
    }

    if (this.canCreateSign) {
      menu.push({
        title: this.$t("timeLine.PartnerReportDetailInfoTimeLineCard.actionCard.signature"),
        action: this.showSignDialog,
        icon: "mdi-file-sign",
        color: "primary",
        isEnabled: this.isSignEnabled
      });
    }

    if (this.canShareContent) {
      menu.push({
        title: this.$t("timeLine.PartnerReportDetailInfoTimeLineCard.actionCard.share"),
        action: this.showShareDialog,
        icon: "mdi-export-variant",
        color: "primary",
        isEnabled: this.isSharingEnabled
      });
    }

    if (this.canCreateTicket) {
      menu.push(
        ...[
          {
            title: this.$t("project.ticket.ticket"),
            action: this.showTicketDialog,
            icon: "mdi-ticket-outline",
            color: "primary",
            isEnabled: true
          }
        ]
      );
    }

    if (this.canShareContent) {
      menu.push({
        title: this.$t("timeLine.PartnerReportDetailInfoTimeLineCard.actionCard.upload"),
        action: this.showUploadDialog,
        icon: "mdi-export-variant",
        color: "primary",
        isEnabled: this.isDocumentUploadActive
      });
    }

    menu.push(
      ...[
        {
          title: this.$t("timeLine.PartnerReportDetailInfoTimeLineCard.actionCard.export"),
          action: this.showExportDialog,
          icon: "mdi-file-send-outline",
          color: "primary",
          isEnabled: false
        }
      ]
    );

    return menu;
  }

  get source(): MrfiktivReferenceGen {
    return {
      refType: "report",
      refId: this.report._id
    };
  }

  get date() {
    if (PartnerModule.report?.timestamps?.created) {
      const locale = this.$t("utility.toLocalDateString").toString() || "de-de";
      return detailedDate(PartnerModule.report?.timestamps?.created, locale);
    }
    return "Kein Datum";
  }

  get report() {
    return PartnerModule.report;
  }

  get partner() {
    return PartnerModule.partner;
  }

  get getImages() {
    return PartnerModule.report.images;
  }

  get witness() {
    return PartnerModule?.report?.witness || [];
  }

  get getRegistrationImageLink(): string {
    if (PartnerModule.report.images.registrations.length === 0) {
      return "";
    }

    if (PartnerModule.report.images.registrations[0].url) {
      return PartnerModule.report.images.registrations[0].url;
    } else {
      return "";
    }
  }

  get metas(): IExportReportMeta[] {
    return ExportModule.exportReportMetas.get(PartnerModule.report._id) || [];
  }

  get user(): IUser {
    return UserModule.user;
  }

  /*
   * States in dropdown list
   */
  get exportOptions() {
    const exportOptions = [];

    if (ExportModule.isKsrSetup) {
      exportOptions.push("KSR");
    }

    if (ExportModule.isDaSetup) {
      exportOptions.push("DA");
    }

    if (ExportModule.isPdrSetup) {
      exportOptions.push("PDR");
    }

    return exportOptions;
  }

  /**
   * Calculates how many meta cards are shown per row
   */
  get metaRowAmount() {
    const maxItemsPerRow = 4;

    return this.metas.length < maxItemsPerRow + 1 ? 12 / this.metas.length : maxItemsPerRow;
  }

  get isSignEnabled() {
    return FeatureModule.isDigitalSignatureEnabled.isSignEnabled;
  }

  get isSharingEnabled() {
    return FeatureModule.isReportEnabled.isSharingEnabled;
  }

  get isTemplateEnabled() {
    return FeatureModule.isTemplateEnabled;
  }

  get thirdPartySystemDa() {
    if (!FeatureModule.isTpsActive) return undefined;

    const tpss = ThirdPartySystemModule.maps.report?.get(this.report.id);

    return tpss?.find(tps => tps.system === TpsEnum.DA);
  }

  get thirdPartySystemKsr() {
    if (!FeatureModule.isTpsActive) return undefined;

    const tpss = ThirdPartySystemModule.maps.report?.get(this.report.id);

    return tpss?.find(tps => tps.system === TpsEnum.KSR);
  }

  @Watch("tab")
  onTabChange() {
    this.debounceScrollToBottom();
  }

  /**
   * Open the dialog to send to ksr or da
   */
  openSendDialog(dialog: string) {
    this.selected = dialog;

    if (dialog === "KSR") {
      (this.$refs.ksrDialog as SendToKsrDialog).open();
    }

    if (dialog === "DA") {
      (this.$refs.daDialog as SendToDaDialog).open();
    }

    if (dialog === "PDR") {
      (this.$refs.pdrDialog as SendToPdrDialog).open();
    }
  }

  showExportDialog() {
    this.$emit("showExportDialog");
  }

  showCommentDialog() {
    this.sheet = true;
    this.$emit("showCommentDialog");
  }

  showEmailDialog() {
    this.$emit("showEmailDialog");
  }

  showSignDialog() {
    this.$emit("showSignDialog");
  }

  showShareDialog() {
    this.$emit("showShareDialog");
  }

  showUploadDialog() {
    this.$emit("showUploadDialog");
  }

  showTicketDialog() {
    this.$emit("showTicketDialog");
  }

  goToSettings() {
    this.$router.push({ name: "Settings" });
  }

  async updateRegistration(registration: RegistrationResultsEntity) {
    this.registrationUpdateLoading = true;
    if (!registration) {
      this.$toast.error("Deine Anfrage konnte nicht versendet werden.");
    }

    const partnerEntity = await PartnerModule.report.addRegistrationResults(registration);

    if (!partnerEntity) {
      this.$toast.error("Deine Anfrage konnte nicht versendet werden.");
    }

    this.registrationUpdateLoading = false;
  }

  get datePreference() {
    return simpleDate(PartnerModule.report.datePreference);
  }
}
