





















import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { IReport } from "@/models/report.entity";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Prop, Vue } from "vue-property-decorator";
import SideCard from "../utility/SideCard.vue";
import PartnerReportDetail from "./PartnerReportDetail.vue";
import { ExportModule } from "@/store/modules/export.store";
import { ActivityLogModule } from "@/store/modules/activity-log.store";
import { FeatureModule } from "@/store/modules/feature.store";
import { ThirdPartySystemModule } from "@/store/modules/third-party-system.store";
import { PageFilterElement } from "@/models/page-filter-element.entity";
import { PageFilterOperationEnum } from "@/lib/utility/data/page-filter-operation.enum";

@Component({
  components: { SideCard, PartnerReportDetail },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class PartnerReportDetailSideCard extends Vue {
  @Prop()
  report!: IReport;

  @Prop({ default: false })
  loading!: boolean;

  mounting = true;

  get fullscreenBreakpoint() {
    return this.$vuetify.breakpoint.width < 1500;
  }

  beforeMount() {
    PartnerModule.setReport(this.report);
  }

  async mounted() {
    this.mounting = true;

    if (FeatureModule.isTpsActive) {
      ThirdPartySystemModule.setFilters([
        new PageFilterElement({
          key: "refs.refId",
          operation: PageFilterOperationEnum.EQUAL,
          value: this.report._id
        })
      ]);
      await ThirdPartySystemModule.fetchFirstPage({ partnerId: this.report.partnerId }).catch(error =>
        this.$log.error(error)
      );
    } else {
      await ExportModule.getExportReportMetaByPartnerIdAndReportId({
        partnerId: this.report.partnerId,
        reportId: this.report._id
      }).catch(error => this.$log.error(error));
    }

    try {
      await ActivityLogModule.loadDocumentsForReport({
        partnerId: this.report.partnerId,
        reportId: this.report._id
      });
    } catch (error) {
      this.$log.error(error);
    } finally {
      this.loading = false;
    }

    this.mounting = false;
  }
}
