var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('the-layout-portal', [_vm.loading ? _c('v-skeleton-loader', {
    attrs: {
      "type": "article, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line"
    }
  }) : _vm.message ? _c('v-container', [_c('partner-message-detail', {
    attrs: {
      "value": _vm.message
    }
  })], 1) : _c('latest-entries-card-empty')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }