var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.item.loading ? _c('v-list-item', {
    attrs: {
      "three-line": ""
    }
  }, [_c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "text-truncate "
  }, [_c('div', {
    staticStyle: {
      "display": "flex"
    }
  }, [_c('div', [_vm.hasOpenDetailListener() ? _c('a', {
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.emitOpenDetail.apply(null, arguments);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.item.system) + " - " + _vm._s(_vm.item.externalId) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.item.system) + " - " + _vm._s(_vm.item.externalId) + " ")])])])]), _c('v-list-item-subtitle', {
    staticClass: "text-xs"
  }, [_vm._v(" " + _vm._s(_vm._f("simpleDoubleDigitDate")(_vm.item.timestamp.created)) + " ")]), !_vm.small ? _c('v-list-item-subtitle', {
    staticClass: "text-xs text-truncate"
  }, [_vm.serviceEvent && _vm.serviceEvent.numberPlate ? _c('v-chip', {
    attrs: {
      "small": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.serviceEvent.numberPlate) + " ")]) : _vm._e(), _vm.serviceEvent && (_vm.serviceEvent.firstName || _vm.serviceEvent.lastName) ? _c('v-chip', {
    attrs: {
      "small": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.serviceEvent.firstName) + " " + _vm._s(_vm.serviceEvent.lastName) + " ")]) : _vm._e(), _vm.serviceEvent && _vm.serviceEvent.customerNumber ? _c('v-chip', {
    attrs: {
      "small": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.serviceEvent.customerNumber) + " ")]) : _vm._e()], 1) : _vm._e()], 1)], 1) : _c('v-skeleton-loader', {
    attrs: {
      "type": "list-item-three-line"
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }